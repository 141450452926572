import Box from "./Box";
import React from "react";
import Text from "./Text";

function MenuItemText({ children }) {
  return (
    <Box transition="opacity 200ms" hover="opacity: 0.5">
      <Text
        fontSize={[18, null, 20, null]}
        uppercase
        color="inherit"
        fontWeight={500}
        fontFamily="ibmPlexMono"
        letterSpacing="3px"
        py="7px"
      >
        {children}
      </Text>
    </Box>
  );
}

export default MenuItemText;
