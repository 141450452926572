import {
  ABOUT_URL,
  BLOG_URL,
  BRANDS_URL,
  CONTACT_URL,
  TAKEBACK_URL,
} from "../core/urls";
import { DARK, LIGHT, RIGHT, SIDE_MENU_CLOSE_Z_INDEX } from "../core/constants";

import Box from "./Box";
import CloseButton from "./CloseButton";
import ExpandableMenuItem from "./ExpandableMenuItem";
import Line from "./Line";
import MenuItem from "./MenuItem";
import SideMenu from "./SideMenu";
import { useAppContext } from "./AppContext";

function MainMenu({ navItems }) {
  const { mobMainMenuOpen, setMainMenuOpen } = useAppContext();

  return (
    <SideMenu
      open={mobMainMenuOpen}
      setOpen={setMainMenuOpen}
      startPos={RIGHT}
      theme={LIGHT}
    >
      <Box
        bg="white"
        position="absolute"
        width="100%"
        top={0}
        px={[15, "4.76vw", null, null]}
        pt={48}
        display="flex"
        justifyContent="flex-end"
        zIndex={SIDE_MENU_CLOSE_Z_INDEX}
      >
        <CloseButton
          theme={DARK}
          onClick={() => {
            setMainMenuOpen(false);
          }}
        />
      </Box>
      <Box
        pt={[88, null, 170, null]}
        px={[25, null, 70, null]}
        pb={100}
        color="black"
      >
        <Box display={["initial", null, "none", null]}>
          {navItems.map((item, index) => {
            if (item.children.length) {
              return (
                <Box key={`${index}-${item.slug}`}>
                  <Line />
                  <ExpandableMenuItem item={item} color="black" />
                </Box>
              );
            } else {
              return (
                <Box key={`${index}-${item.slug}`}>
                  <Line />
                  <MenuItem name={item.name} slug={item.slug} color="black" />
                </Box>
              );
            }
          })}
        </Box>
        <Line />
        <MenuItem name="About" slug={ABOUT_URL} color="black" />
        <Line />
        <MenuItem name="The re_edit Blog" slug={BLOG_URL} color="black" />
        <Line />
        <MenuItem name="Takeback" slug={TAKEBACK_URL} color="black" />
        <Line />
        <MenuItem name="Partnerships" slug={BRANDS_URL} color="black" />
        <Line />
        <MenuItem name="Contact us" slug={CONTACT_URL} color="black" />
        <Line />
      </Box>
    </SideMenu>
  );
}

export default MainMenu;
