import React, { useEffect, useState, useRef } from "react";

import Box from "./Box";
import Button from "./Button";
import NavItemDropdown from "./NavItemDropdown";
import NavItemDropdownSubMenu from "./NavItemDropdownSubMenu";
import PropTypes from "prop-types";
import SmallCaps from "./SmallCaps";
import { useViewportContext } from "./ViewportContext";

function NavItemWithDropdown({
  item,
  firstOfType,
  color,
  dropdownBg,
  dropdownColor,
  setNavDropdownOpen,
}) {
  const timer = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { innerWidth, isTouch } = useViewportContext();
  const LARGE_TABLET = 1180;

  useEffect(() => {
    if (setNavDropdownOpen) {
      if (dropdownOpen) {
        setNavDropdownOpen(true);
      } else {
        setNavDropdownOpen(false);
      }
    }
  }, [dropdownOpen]);

  return (
    <Box
      height="calc(100% + 1px)"
      mb="-1px"
      onMouseEnter={() => {
        if (!isTouch) {
          if (timer.current) {
            clearTimeout(timer.current);
          }
          setDropdownOpen(true);
        }
      }}
      onMouseLeave={() => {
        timer.current = setTimeout(() => {
          setDropdownOpen(false);
        }, 200);
      }}
    >
      <Button
        height="100%"
        onClick={() => {
          setDropdownOpen(!dropdownOpen);
        }}
        aria-label={`Open ${item.name}`}
      >
        <Box
          pl={firstOfType ? 50 : 50}
          pr={50}
          mr={-29}
          ml={firstOfType ? 0 : -29}
          height="100%"
          display="flex"
          alignItems="center"
          transition="opacity 200ms"
          opacity={dropdownOpen ? 0.5 : 1}
          flexDirection="column"
          justifyContent="center"
        >
          <SmallCaps color={color}>{item.name}</SmallCaps>
          <Box
            height="1px"
            width="100%"
            mt="1px"
            transition="opacity 200ms"
            opacity={dropdownOpen ? 1 : 0}
            borderBottom="1px solid"
            borderColor={color}
          />
        </Box>
      </Button>

      {item.hasSubMenu ? (
        <NavItemDropdownSubMenu
          item={item}
          bg={dropdownBg}
          color={dropdownColor}
          active={dropdownOpen}
        />
      ) : (
        <NavItemDropdown
          item={item}
          bg={dropdownBg}
          color={dropdownColor}
          active={dropdownOpen}
        />
      )}
    </Box>
  );
}

export default NavItemWithDropdown;

NavItemWithDropdown.propTypes = {
  item: PropTypes.object.isRequired,
  firstOfType: PropTypes.bool,
  color: PropTypes.string,
  dropdownBg: PropTypes.string.isRequired,
  dropdownColor: PropTypes.string.isRequired,
  fadeBackground: PropTypes.bool,
};
