import { useEffect, useRef, useState } from "react";

import { useInView } from "react-intersection-observer";

export function useScrollDirection() {
  // https://stackoverflow.com/questions/57088861/react-setstate-hook-from-scroll-event-listener

  const prevScrollRef = useRef(0);
  const scrollDownCounterRef = useRef(0);

  const [scrollingUp, setScrollingUp] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      const scrollingDown = prevScrollRef.current < currentScrollY;
      const overScrollBottom =
        currentScrollY >= document.body.scrollHeight - window.innerHeight;

      if (currentScrollY < 50) {
        if (!scrollingUp) {
          setScrollingUp(true);
        }
        return;
      }

      if (prevScrollRef.current > currentScrollY && currentScrollY > 0) {
        scrollDownCounterRef.current = 0;
        if (!scrollingUp && !overScrollBottom) {
          setScrollingUp(true);
        }
      } else if (scrollingDown) {
        scrollDownCounterRef.current += 1;
        if (scrollDownCounterRef.current > 5) {
          if (scrollingUp) {
            setScrollingUp(false);
          }
        }
      }
      prevScrollRef.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollingUp]);

  return scrollingUp;
}

export function useEnteredViewport(threshold) {
  // A hook for when you want to trigger an action the first time an
  // element enters the viewport
  const { ref, inView } = useInView({
    threshold: threshold,
  });
  const [entered, setEntered] = useState(false);

  useEffect(() => {
    if (inView) {
      setEntered(true);
    }
  }, [inView]);

  return { ref, entered };
}

export function useHovered(ref) {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("mouseenter", () => {
        setHovered(true);
      });
      ref.current.addEventListener("mouseleave", () => {
        setHovered(false);
      });
    }
  }, [ref]);

  return hovered;
}

export function useInterval(callback, delay) {
  const callbackRef = useRef();

  // Remember the latest callback.
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      callbackRef.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
