import React, { useRef, useState } from "react";

import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import Button from "./Button";
import DropdownMenuItems from "./DropdownMenuItems";
import SmallCaps from "./SmallCaps";
import { useHovered } from "../core/hooks";

function NavItem({ color, item, active, firstOfType, handleClick }) {
  const ref = useRef(null);
  const hovered = useHovered(ref);

  return (
    <Button
      height="100%"
      pb={20}
      pt={10}
      onClick={handleClick}
      aria-label={`Open ${item.name}`}
      ref={ref}
    >
      <Box
        px={22}
        ml={firstOfType ? -22 : 0}
        height="100%"
        display="flex"
        alignItems="center"
        transition="opacity 200ms"
        opacity={active || hovered ? 0.5 : 1}
        flexDirection="column"
        justifyContent="center"
      >
        <SmallCaps color={color}>{item.name}</SmallCaps>
        <Box
          height="1px"
          width="100%"
          mt="1px"
          transition="opacity 200ms"
          opacity={active || hovered ? 1 : 0}
          borderBottom="1px solid"
          borderColor={color}
        />
      </Box>
    </Button>
  );
}

function NavItemDropdownSubMenu({ bg, color, item, active }) {
  const [currentSubMenu, setCurrentSubMenu] = useState(item.children[0].slug);
  return (
    <Box
      position="absolute"
      left={0}
      bottom={-261}
      height={260}
      width="100%"
      zIndex={90}
      pointerEvents={active ? "all" : "none"}
      opacity={active ? 1 : 0}
      transition="opacity 200ms"
    >
      <BoxWithGutter
        height="100%"
        width="100%"
        bg={bg}
        py={20}
        display="flex"
        flexDirection="column"
      >
        <Box display="flex" width="100%">
          {item.children.map((childItem, index) => {
            return (
              <NavItem
                key={childItem.slug}
                item={childItem}
                color={color}
                firstOfType={index === 0}
                active={childItem.slug === currentSubMenu}
                handleClick={() => {
                  setCurrentSubMenu(childItem.slug);
                }}
              />
            );
          })}
        </Box>
        <Box height="calc(100% - 50px)" position="relative" display="flex">
          {item.children.map((childItem) => {
            return childItem.slug === currentSubMenu ? (
              <DropdownMenuItems
                key={childItem.slug}
                item={childItem}
                color={color}
              />
            ) : null;
          })}
        </Box>
      </BoxWithGutter>
    </Box>
  );
}

export default NavItemDropdownSubMenu;

NavItemDropdownSubMenu.propTypes = {};
