import { DARK, LIGHT, NAV_Z_INDEX } from "../core/constants";
import React, { useEffect, useRef, useState } from "react";

import Box from "./Box";
import BurgerButton from "./BurgerButton";
import CartButton from "./CartButton";
import CartIconBlack from "../assets/Icn-Basket-Mobile-Black.svg";
import CartIconWhite from "../assets/Icn-Basket-Mobile-White.svg";
import Link from "next/link";
import LogoBlack from "../assets/reskinned-black.svg";
import LogoWhite from "../assets/reskinned-white.svg";
import NavItemLink from "./NavItemLink";
import NavItemWithDropdown from "./NavItemWithDropdown";
import PropTypes from "prop-types";
import Text from "./Text";
import debounce from "underscore/modules/debounce";
import { useCartContext } from "./CartContext";
import { useHovered } from "../core/hooks";
import { useViewportContext } from "./ViewportContext";

function Nav({ theme, navItems, pageTheme }) {
  const { isTouch } = useViewportContext();
  const [navDropdownOpen, setNavDropdownOpen] = useState(false);
  const ref = useRef(null);
  const {
    cart: { lines },
  } = useCartContext();
  const hovered = useHovered(ref);
  const [filled, setFilled] = useState(false);

  useEffect(() => {
    // Check if nav should be filled to start with
    if (window.scrollY > 0) {
      setFilled(true);
    }

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setFilled(true);
      } else {
        setFilled(false);
      }
    };
    const debouncedHandleScroll = debounce(handleScroll, 20);

    window.addEventListener("scroll", debouncedHandleScroll);

    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, []);

  let bg;
  if (theme === LIGHT && !filled && !hovered) bg = "transparent";
  if (
    theme === LIGHT &&
    !filled &&
    !hovered &&
    typeof pageTheme !== "undefined"
  )
    bg = pageTheme;
  if (theme === LIGHT && hovered && !isTouch) bg = "white";
  if (theme === LIGHT && filled) bg = "white";
  if (theme === LIGHT && navDropdownOpen) bg = "white";
  if (theme === DARK) bg = "black";
  return (
    <>
      <Box
        ref={ref}
        px={[15, "4.76vw", null, null]}
        bg={bg}
        position="sticky"
        top={0}
        height={60}
        color={theme === DARK ? "white" : "black"}
        borderTop="1px solid black"
        borderBottom="1px solid black"
        display="flex"
        justifyContent="space-between"
        transition="transform 200ms ease-out, background-color 200ms, color 200ms"
        zIndex={NAV_Z_INDEX}
      >
        <Box display="flex" alignItems="center" height="100%">
          <Link href="/" passHref>
            <Box
              as="a"
              mb="-5px"
              position="relative"
              width={157}
              height={20}
              aria-label="Home"
            >
              <Box position="absolute" top={0} left={0}>
                <LogoWhite aria-label="Reskinned logo" />
              </Box>
              <Box
                transition="opacity 200ms"
                opacity={theme === LIGHT ? 1 : 0}
                position="absolute"
                top={0}
                left={0}
              >
                <LogoBlack aria-label="Reskinned logo" />
              </Box>
            </Box>
          </Link>
          <Box ml={-20} display={["none", null, "flex", null]} height="100%">
            {navItems.map((item, index) => {
              if (item.children.length) {
                return (
                  <NavItemWithDropdown
                    key={`${index}-${item.slug}`}
                    color="inherit"
                    item={item}
                    firstOfType={index === 0}
                    dropdownBg={theme === LIGHT ? "white" : "black"}
                    dropdownColor={theme === LIGHT ? "black" : "white"}
                    setNavDropdownOpen={setNavDropdownOpen}
                  />
                );
              } else {
                return (
                  <NavItemLink
                    key={`${index}-${item.slug}`}
                    color="inherit"
                    item={item}
                    firstOfType={index === 0}
                  />
                );
              }
            })}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <CartButton>
            <Box display="flex" alignItems="center">
              <Box position="relative" height={20} width={20}>
                <Box position="absolute" top={0} left={0}>
                  <CartIconWhite />
                </Box>
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  transition="opacity 200ms"
                  hover="opacity: 0.5"
                  opacity={theme === LIGHT ? 1 : 0}
                >
                  <CartIconBlack />
                </Box>
              </Box>
              <Text fontSize={14} fontWeight={500} ml="2px">
                ({lines.length})
              </Text>
            </Box>
          </CartButton>

          <Box
            pl={15}
            display="flex"
            alignItems="center"
            transition="opacity 200ms"
            hover="opacity: 0.5"
          >
            <BurgerButton bg={theme === LIGHT ? "black" : "white"} />
          </Box>
        </Box>
      </Box>
      <Box
        bg="rgba(0,0,0,0.6)"
        height="100vh"
        width="100vw"
        position="fixed"
        top={0}
        zIndex={NAV_Z_INDEX - 1}
        pointerEvents="none"
        opacity={navDropdownOpen ? 1 : 0}
        transition="opacity 200ms"
      />
    </>
  );
}

export default Nav;

Nav.propTypes = {
  theme: PropTypes.string.isRequired,
  pageTheme: PropTypes.string,
};
