import { DARK, LEFT, LIGHT, RIGHT, SIDE_MENU_Z_INDEX } from "../core/constants";
import React, { useEffect, useRef } from "react";

import Box from "./Box";
import PropTypes from "prop-types";

function SideMenu({ open, setOpen, startPos, mobileOnly, theme, children }) {
  const scrollableBoxRef = useRef();

  useEffect(() => {
    if (open) scrollableBoxRef.current.scrollTop = 0;
  }, [open]);

  return (
    <Box
      height="100vh"
      position="fixed"
      top={0}
      zIndex={SIDE_MENU_Z_INDEX}
      width="100%"
      pointerEvents={open ? "all" : "none"}
      mx="auto"
      display={["block", null, mobileOnly ? "none" : null, null]}
    >
      <Box
        bg="rgba(0,0,0,0.3)"
        transition="opacity 100ms"
        opacity={open ? 1 : 0}
        width="100%"
        height="100%"
        onClick={() => setOpen(false)}
      />
      <Box
        bg={theme === DARK ? "black" : "white"}
        position="absolute"
        width={["87%", 517, null, null]}
        left={startPos === LEFT ? 0 : undefined}
        right={startPos === RIGHT ? 0 : undefined}
        top={0}
        height="100%"
        transition="transform 300ms ease-out"
        transform={
          open
            ? "translate3d(0,0,0)"
            : startPos === LEFT
            ? "translate3d(-100%, 0, 0)"
            : "translate3d(100%, 0, 0)"
        }
      >
        <Box ref={scrollableBoxRef} height="100%" overflow="auto">
          {children}
        </Box>
      </Box>
      {!mobileOnly && (
        <Box
          bg="white"
          height="100%"
          width={517}
          position="absolute"
          left={0}
          top={0}
          transform={
            startPos === LEFT
              ? "translate3d(-100%, 0, 0)"
              : "translate3d(-100%, 0, 0)"
          }
          display={["none", "block", null, null]}
        />
      )}
    </Box>
  );
}

export default SideMenu;

SideMenu.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  startPos: PropTypes.string.isRequired,
  mobileOnly: PropTypes.bool,
  theme: PropTypes.string.isRequired,
};
