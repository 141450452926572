import PropTypes from "prop-types";
import styled from "styled-components";

const AspectRatioBox = styled.div`
  height: 0;
  padding-bottom: ${(props) => {
    return props.pb;
  }};
  background-color: ${(props) => {
    return props.background;
  }};
  position: relative;
  width: 100%;
`;

AspectRatioBox.defaultProps = {
  background: "#f5f5f1",
};

AspectRatioBox.propTypes = {
  pb: PropTypes.string.isRequired,
  background: PropTypes.string,
};

export default AspectRatioBox;
