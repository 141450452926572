import React from "react";
import Text from "./Text";

function SmallCaps({ color, underline, children, ...props }) {
  return (
    <Text
      fontSize={[14, 14, 14, 14]}
      letterSpacing="2px"
      color={color}
      uppercase
      underline={underline}
      fontWeight={500}
      fontFamily="ibmPlexMono"
      {...props}
    >
      {children}
    </Text>
  );
}

export default SmallCaps;
