import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import DropdownMenuItems from "./DropdownMenuItems";
import React from "react";

function NavItemDropdown({ bg, color, item, active }) {
  return (
    <Box
      position="absolute"
      left={0}
      bottom={-261}
      height={260}
      width="100%"
      zIndex={90}
      pointerEvents={active ? "all" : "none"}
      opacity={active ? 1 : 0}
      transition="opacity 200ms"
    >
      <BoxWithGutter height="100%" width="100%" bg={bg} py={20} display="flex">
        <DropdownMenuItems item={item} color={color} />
      </BoxWithGutter>
    </Box>
  );
}

export default NavItemDropdown;

NavItemDropdown.propTypes = {};
