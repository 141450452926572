import { DARK, LIGHT } from "../core/constants";

import Button from "./Button";
import PropTypes from "prop-types";
import React from "react";
import SmallCaps from "./SmallCaps";

function ButtonPrimary({ theme, onClick, fullWidth, disabled, children }) {
  return (
    <Button
      bg={theme === DARK ? "black" : "white"}
      px={10}
      height={30}
      width={fullWidth ? "100%" : ""}
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      border={theme === DARK ? "1px solid black" : "1px solid white"}
      color={theme === DARK ? "white" : "black"}
      transition="background-color 200ms, color 200ms"
      hover={
        disabled
          ? ""
          : theme === DARK
          ? "background-color: white; color: black"
          : "background-color: white; color: black"
      }
      opacity={disabled ? 0.5 : 1}
      disabled={disabled}
    >
      <SmallCaps color="inherit">{children}</SmallCaps>
    </Button>
  );
}

export default ButtonPrimary;

ButtonPrimary.propTypes = {
  theme: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};
