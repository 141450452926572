import Box from "./Box";
import Button from "./Button";
import { DARK } from "../core/constants";
import PropTypes from "prop-types";
import React from "react";

function CloseStripe(props) {
  return (
    <Box
      bg={props.theme === DARK ? "black" : "white"}
      height="2px"
      width="100%"
      position="absolute"
      top="50%"
      transition="transform 200ms"
      {...props}
    />
  );
}

function CloseButton({ theme, onClick }) {
  return (
    <Button
      height={22}
      position="relative"
      width={22}
      onClick={onClick}
      aria-label="Close"
    >
      <CloseStripe theme={theme} transform="rotate(45deg)" />
      <CloseStripe theme={theme} transform="rotate(-45deg)" />
    </Button>
  );
}

export default CloseButton;

CloseButton.propTypes = {
  theme: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
