import { DARK, SIDE_MENU_Z_INDEX } from "../core/constants";
import React, { useEffect, useRef } from "react";

import Box from "./Box";
import ButtonPrimary from "./ButtonPrimary";
import CloseButton from "./CloseButton";
import Line from "./Line";
import LineItem from "./LineItem";
import Text from "./Text";
import { formatPrice } from "../core/utils";
import { useCartContext } from "./CartContext";

function CheckoutButton() {
  const { checkout } = useCartContext();
  return (
    <ButtonPrimary onClick={checkout} fullWidth theme={DARK}>
      Go to checkout
    </ButtonPrimary>
  );
}

function Cart() {
  const scrollableBoxRef = useRef();
  const { cartOpen, setCartOpen, cart } = useCartContext();

  useEffect(() => {
    if (cartOpen) scrollableBoxRef.current.scrollTop = 0;
  }, [cartOpen]);

  return (
    <Box
      height="100vh"
      position="fixed"
      top={0}
      zIndex={SIDE_MENU_Z_INDEX}
      width="100%"
      pointerEvents={cartOpen ? "all" : "none"}
    >
      <Box
        bg="rgba(0,0,0,0.3)"
        transition="opacity 100ms"
        opacity={cartOpen ? 1 : 0}
        width="100%"
        height="100%"
        onClick={() => setCartOpen(!cartOpen)}
      />
      <Box
        bg="white"
        position="absolute"
        width={["100%", "75%", 700, null]}
        right={0}
        top={0}
        height="100%"
        transition="transform 380ms ease-out"
        transform={cartOpen ? "translate3d(0,0,0)" : "translate3d(100%, 0, 0)"}
      >
        <Box
          position="sticky"
          top={0}
          pt={30}
          right={0}
          width="100%"
          height={90}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          pr={[57, null, 70, null]}
          bg="white"
        >
          <CloseButton theme={DARK} onClick={() => setCartOpen(false)} />
        </Box>
        <Box
          ref={scrollableBoxRef}
          height="calc(100% - 90px)"
          overflow="scroll"
          pb={[88, null, 130, null]}
          px={[25, null, 40, null]}
        >
          {cart.lines.length > 0 ? (
            <>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                maxWidth={370}
                mx="auto"
                pb={[20, null, 30, null]}
              >
                <Text textAlign="center" pb={[26, null, 32, null]}>
                  Your cart total is{" "}
                  {formatPrice(cart.estimatedCost.totalAmount.amount)}
                </Text>
                <CheckoutButton />
              </Box>
              <Line />
              {cart.lines.map(({ node: lineItem }) => {
                return <LineItem key={lineItem.id} lineItem={lineItem} />;
              })}

              <Line />
              <Box
                py="10px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text fontSize={[16, 16, 19, 19]} fontWeight={700} uppercase>
                  Total
                </Text>
                <Text fontSize={[16, 16, 19, 19]} fontWeight={700} uppercase>
                  {formatPrice(cart.estimatedCost.totalAmount.amount)}
                </Text>
              </Box>
              <Line />
              <Box maxWidth={370} mx="auto" mt={[20, null, 30, null]}>
                <CheckoutButton />
              </Box>
            </>
          ) : (
            <Text textAlign="center" pb={[26, null, 32, null]}>
              Your cart is empty!
            </Text>
          )}
        </Box>
      </Box>
      <Box
        bg="white"
        height="100%"
        width={700}
        position="absolute"
        right={0}
        top={0}
        transform="translate3d(100%, 0, 0)"
        display={["none", null, "block", null]}
      />
    </Box>
  );
}

export default Cart;
