import AspectRatioBox from "./AspectRatioBox";
import PropTypes from "prop-types";
import React from "react";
import Image from "./Image";

function SquareImage({ src, alt, sizes, objectFit, background, children }) {
  return (
    <AspectRatioBox pb="100%" background={background}>
      <Image src={src} alt={alt} objectFit={objectFit} sizes={sizes} />
      {children}
    </AspectRatioBox>
  );
}

export default SquareImage;

SquareImage.defaultProps = {
  objectFit: "cover",
};

SquareImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  objectFit: PropTypes.string,
  background: PropTypes.string,
};
