import React, { useState } from "react";

import Box from "./Box";
import Button from "./Button";
import ChevronIcon from "../assets/chevron.svg";
import ChevronWhiteIcon from "../assets/chevron-white.svg";
import PropTypes from "prop-types";

function ExpandableSection({
  heading,
  content,
  chevronColor,
  openByDefault,
  narrow,
}) {
  const [active, setActive] = useState(openByDefault ? true : false);
  return (
    <Box>
      <Button
        width="100%"
        onClick={() => setActive(!active)}
        pb={narrow ? 20 : [20, null, 30, null]}
        pt={narrow ? 0 : [20, null, 30, null]}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pr={20}
          pl="5px"
        >
          {heading}
          <Box
            transform={active ? "rotate(90deg)" : "rotate(270deg)"}
            display="flex"
            alignItems="center"
          >
            {chevronColor === "white" ? (
              <ChevronWhiteIcon aria-label="Arrow" />
            ) : (
              <ChevronIcon aria-label="Arrow" />
            )}
          </Box>
        </Box>
      </Button>
      {active && <Box pb={[20, null, 30, null]}>{content}</Box>}
    </Box>
  );
}

export default ExpandableSection;

ExpandableSection.propTypes = {
  heading: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  chevronColor: PropTypes.string,
  openByDefault: PropTypes.bool,
};
