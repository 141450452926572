import Box from "./Box";
import { DARK } from "../core/constants";
import PropTypes from "prop-types";
import React from "react";

function Line({ theme }) {
  return (
    <Box
      width="100%"
      borderBottom={theme === DARK ? "1px solid black" : "1px solid white"}
    />
  );
}

export default Line;

Line.propTypes = {
  theme: PropTypes.string,
};

Line.defaultProps = {
  theme: DARK,
};
