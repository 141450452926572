import {
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  space,
  typography,
} from "styled-system";

import PropTypes from "prop-types";
import styled from "styled-components";

const Input = styled.input.attrs(
  ({
    type,
    placeholder,
    onChange,
    autoComplete,
    value,
    required,
    disabled,
    error,
  }) => ({
    type: type,
    placeholder: placeholder,
    onChange: onChange,
    autoComplete: autoComplete,
    required: required,
    value: value,
    disabled: disabled,
    error: error,
  })
)`
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
  ${typography}
  border-style:solid;
  -webkit-appearance: none;
  &:focus {
    border-color: ${(props) => {
      return props.theme.colors.foreground;
    }};
    outline: none !important;
  }
  ${(props) => {
    if (props.error) {
      return `border-color: ${props.theme.colors.error} !important;`;
    }
  }};
  ${(props) => {
    if (props.placeholderColor) {
      return `&::placeholder {
        color: ${props.placeholderColor};
      }`;
    }
  }}
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

Input.propTypes = {
  type: PropTypes.oneOf(["text", "email", "number", "password", "tel"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  autoComplete: PropTypes.oneOf([
    "name",
    "given-name",
    "family-name",
    "email",
    "new-password",
    "current-password",
    "organization",
    "street-address",
    "address-line1",
    "address-line2",
    "address-line3",
    "address-level1",
    "country-name",
    "postal-code",
    "tel",
    "off",
  ]),
};

export default Input;
