import React, { useEffect, useState } from "react";

import Box from "./Box";
import Input from "./Input";
import { LIGHT } from "../core/constants";

function NewsletterSignup({ brand, theme, buttonText }) {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      const extraFields = ["$consent"];

      if (brand) {
        extraFields.push(brand.name);
      }

      KlaviyoSubscribe.attachToForms("#email_signup", {
        hide_form_on_success: true,
        success: () => {
          setSuccess(true);
        },
        extra_properties: {
          $source: "Signup form",
        },
        list: "R7mjEu",
        content: {
          extra_fields: extraFields,
        },
      });
    }
  }, []);

  if (success) {
    return (
      <Box color={theme === LIGHT ? "white" : "black"}>
        Thanks for signing up!
      </Box>
    );
  }

  return (
    <form
      id="email_signup"
      name="email-form"
      data-name="Email Form"
      action="https://manage.kmail-lists.com/subscriptions/subscribe?a=VU88Bb&g=R7mjEu"
      method="post"
      style={{ width: "100%" }}
      aria-label="Email form"
    >
      <input type="hidden" name="g" value="R7mjEu" />
      <input
        type="hidden"
        name="$fields"
        value={`$consent${brand ? "," + brand.name : ""}`}
      />
      <Box display="flex" width="100%">
        <input
          type="hidden"
          name="$consent"
          id="consent-email"
          value="email"
        ></input>
        {brand && (
          <input
            type="hidden"
            name={brand.name}
            id="consent-email"
            value={true}
          ></input>
        )}
        <Input
          type="email"
          placeholder="Enter email"
          name="email"
          data-name="email"
          id="k_id_email"
          required
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          bg={theme === LIGHT ? "black" : "white"}
          border={theme === LIGHT ? "1px solid white" : "1px solid black"}
          borderRadius={0}
          color={theme === LIGHT ? "white" : "black"}
          placeholderColor={theme === LIGHT ? "white" : null}
          fontSize="14px"
          height={30}
          width="100%"
          px="10px"
          mr="2px"
        />
        <Box
          bg={theme === LIGHT ? "white" : "black"}
          px={10}
          height={30}
          display="flex"
          justifyContent="center"
          alignItems="center"
          border={theme === LIGHT ? "1px solid white" : "1px solid black"}
          color={theme === LIGHT ? "black" : "white"}
          transition="background-color 200ms, color 200ms"
          hover="background-color: white; color: black"
          cursor="pointer"
        >
          <input
            type="submit"
            value={buttonText || "Submit"}
            style={{
              textTransform: "uppercase",
              letterSpacing: "2px",
              fontSize: 14,
              cursor: "pointer",
            }}
            data-wait="Please wait"
          />
        </Box>
      </Box>
    </form>
  );
}

export default NewsletterSignup;
