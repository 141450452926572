import { border, color, flexbox, layout, position, space } from "styled-system";

import styled from "styled-components";

const Button = styled.button`
  ${color}
  ${space}
  ${border}
  ${flexbox}
  ${layout}
  ${position}
  cursor: pointer;
  ${(props) => {
    if (props.disabled) {
      return "cursor: default;";
    }
  }}
  ${(props) => {
    if (props.transition) {
      return `transition: ${props.transition};`;
    }
  }}
  ${(props) => {
    if (props.hover) {
      return `
      &:hover {
        ${props.hover};
      }
      `;
    }
  }}
`;

Button.defaultProps = {
  color: "inherit",
};

export default Button;
