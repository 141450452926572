import React, { useEffect, useRef } from "react";

import Box from "./Box";

function Ticker({ bg, height, tickerItem, pixelsPerSecond }) {
  const spansRef = useRef([]);

  useEffect(() => {
    const spanWidth = spansRef.current[0].getBoundingClientRect().width;
    const animationDuration = Math.round((spanWidth / pixelsPerSecond) * 10);

    spansRef.current.forEach((span) => {
      span.style.animationDuration = `${animationDuration}s`;
    });
  }, []);

  let items = [];

  {
    for (let i = 0; i < 15; i++) {
      items.push(tickerItem);
    }
  }

  return (
    <Box width="100vw" zIndex={100}>
      <Box
        height={height}
        width="100%"
        bg={bg}
        overflow="hidden"
        display="flex"
        alignItems="center"
      >
        {items.map((item, index) => {
          return (
            <span
              key={index}
              className="Ticker-span"
              ref={(el) => (spansRef.current[index] = el)}
            >
              {item}
            </span>
          );
        })}
      </Box>
    </Box>
  );
}

export default Ticker;

Ticker.defaultProps = {
  pixelsPerSecond: 250,
};
