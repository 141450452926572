export const TAKEBACK_URL = "/takeback";
export const PRODUCT_URL = "/product";
export const PAGES_URL = "/pages";
export const ABOUT_URL = "/about";
export const BRANDS_URL = "/brand-partnerships";
export const DELIVERY_URL = "/pages/resale/delivery-policy";
export const BLOG_URL = "/the-re-edit-blog";
export const CONTACT_URL = "/contact-us";
export const LEGAL_URL = "/legal";
export const MENSWEAR_URL = "/menswear";
export const WOMENSWEAR_URL = "/womenswear";
export const GIRLS_URL = "/girls";
export const BOYS_URL = "/boys";
export const BABY_URL = "/baby";
export const KIDS_URL = "/kids";
export const LATEST_DROP_URL = "/latest-drop";

export const FUTUREPROOF_URL =
  "https://app.poweredbyfutureproof.com/co2/reskinned";
