import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
} from "styled-system";

import styled from "styled-components";

const Box = styled.div`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${position}
  ${shadow}
  ${(props) => {
    if (props.gap) {
      return `gap: ${props.gap};`;
    }
  }}
  ${(props) => {
    if (props.transform) {
      return `transform: ${props.transform};`;
    }
  }}
  ${(props) => {
    if (props.transformOrigin) {
      return `transform-origin: ${props.transformOrigin};`;
    }
  }}
  ${(props) => {
    if (props.transition) {
      return `transition: ${props.transition};`;
    }
  }}
  ${(props) => {
    if (props.transitionDelay) {
      return `transition-delay: ${props.transitionDelay}ms;`;
    }
  }}
  ${(props) => {
    if (props.pointerEvents) {
      return `pointer-events: ${props.pointerEvents};`;
    }
  }}
  ${(props) => {
    if (props.cursor) {
      return `cursor: ${props.cursor};`;
    }
  }}
  @media (hover: hover) {
    ${(props) => {
      if (props.hover) {
        return `
        &:hover {
          ${props.hover};
        }
        `;
      }
    }}
  }
`;

export default Box;
