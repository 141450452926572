import Button from "./Button";
import React from "react";
import { useCartContext } from "./CartContext";

function CartButton({ children }) {
  const { setCartOpen } = useCartContext();
  return (
    <Button
      aria-label="Open cart"
      onClick={() => {
        setCartOpen(true);
      }}
    >
      {children}
    </Button>
  );
}

export default CartButton;
