import { color, layout, space, typography } from "styled-system";

import styled from "styled-components";

function getWhiteSpace(fontSize, lineHeight, capSize, capOffset) {
  const totalSize = fontSize * lineHeight;
  const capHeight = fontSize * capSize;
  const whiteSpace = (totalSize - capHeight) / 2;
  return {
    top: whiteSpace - capOffset * fontSize,
    bottom: whiteSpace + capOffset * fontSize,
  };
}
const Text = styled.p`
  ${color}
  ${space}
  ${layout}
  ${typography}
  ${(props) => {
    if (props.marginCorrection) {
      const capSize = props.theme.capSizes[props.fontFamily];
      const capHeight = capSize.height;
      const capOffset = capSize.offset;
      if (Array.isArray(props.fontSize)) {
        const whiteSpace = getWhiteSpace(
          props.fontSize[0],
          props.lineHeight,
          capHeight,
          capOffset
        );
        const defaultValue = `
        &::before {
          margin-top: ${-whiteSpace.top}px;
        }
        &::after {
          margin-bottom: ${-whiteSpace.bottom}px;
        }
      `;
        const mqValues = Object.keys(props.theme.mediaQueries).map(
          (key, index) => {
            const whiteSpace = getWhiteSpace(
              props.fontSize[index + 1],
              props.lineHeight,
              capHeight,
              capOffset
            );
            return `${props.theme.mediaQueries[key]} {
          &::before {
            margin-top: ${-whiteSpace.top}px;
          }
          &::after {
            margin-bottom: ${-whiteSpace.bottom}px;
          }
        }`;
          }
        );
        return defaultValue + mqValues.join("");
      } else {
        const whiteSpace = getWhiteSpace(
          props.fontSize,
          props.lineHeight,
          capHeight,
          capOffset
        );
        return `
        &::before {
          margin-top: ${-whiteSpace.top}px;
        }
        &::after {
          margin-bottom: ${-whiteSpace.bottom}px;
        }
      `;
      }
    }
  }}
  ${(props) => {
    if (props.marginCorrection) {
      return `
        &::before {
          content: "";
          display: block;
        }
        &::after {
          content: "";
          display: block;
        }
      `;
    }
  }}
  text-decoration: ${(props) => {
    if (props.underline) return "underline";
  }};
  text-transform: ${(props) => {
    if (props.uppercase) return "uppercase";
  }};
  font-style: ${(props) => {
    if (props.italic) return "italic";
  }};
  transition: ${(props) => {
    if (props.transition) return props.transition;
  }};
  white-space: ${(props) => {
    if (props.whiteSpace) {
      return props.whiteSpace;
    } else {
      return "pre-line";
    }
  }};
`;
Text.defaultProps = {
  fontSize: 16,
  fontFamily: "ibmPlexSans",
  marginCorrection: true,
  lineHeight: 1.4,
};
export default Text;
