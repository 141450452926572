import Box from "./Box";
import ExpandableSection from "./ExpandableSection";
import Link from "next/link";
import MenuItemText from "./MenuItemText";
import PropTypes from "prop-types";
import React from "react";
import Text from "./Text";

function MenuChildItem({ item, color }) {
  return (
    <Link href={item.slug} passHref>
      <Text
        uppercase
        fontSize={12}
        fontFamily="ibmPlexMono"
        letterSpacing="1.7px"
        fontWeight={500}
        my={15}
        color={item.name === "Sale" ? "pink" : color}
      >
        {item.name}
      </Text>
    </Link>
  );
}

function ChildMenuItems({ item, color }) {
  return (
    <Box pl={30}>
      {item.slug && (
        <MenuChildItem
          key={item.slug}
          item={{ name: `All ${item.name}`, slug: item.slug }}
          color={color}
        />
      )}
      {item.children.map((childItem) => {
        return (
          <MenuChildItem key={childItem.slug} item={childItem} color={color} />
        );
      })}
    </Box>
  );
}

function ExpandableMenuItem({ item, color }) {
  return (
    <ExpandableSection
      chevronColor={color}
      heading={<MenuItemText>{item.name}</MenuItemText>}
      content={
        item.hasSubMenu ? (
          <Box pl={30}>
            {item.children.map((childItem) => {
              return (
                <ExpandableSection
                  key={childItem.slug}
                  narrow
                  chevronColor={color}
                  heading={
                    <Text
                      fontSize={15}
                      uppercase
                      color="inherit"
                      fontWeight={700}
                      fontFamily="ibmPlexMono"
                      letterSpacing="3px"
                      py="7px"
                    >
                      {childItem.name}
                    </Text>
                  }
                  content={<ChildMenuItems item={childItem} color={color} />}
                />
              );
            })}
          </Box>
        ) : (
          <ChildMenuItems item={item} color={color} />
        )
      }
    />
  );
}

export default ExpandableMenuItem;

ExpandableMenuItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      })
    ),
  }),
  color: PropTypes.string.isRequired,
};
