import Box from "./Box";
import Link from "next/link";
import MenuItemText from "./MenuItemText";
import PropTypes from "prop-types";
import React from "react";

function MenuItem({ name, slug }) {
  return (
    <Box py={[20, null, 30, null]} pl="5px">
      <Link href={slug} passHref>
        <Box as="a" display="flex" alignItems="center">
          <MenuItemText>{name}</MenuItemText>
        </Box>
      </Link>
    </Box>
  );
}

export default MenuItem;

MenuItem.propTypes = {
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
