import React, { useEffect, useState, useRef } from "react";
import { background, border, layout, position, space } from "styled-system";

import { FILL } from "../core/constants";
import Image from "next/image";
import PropTypes from "prop-types";
import styled from "styled-components";

const ImageWrapper = styled.span`
  ${space}
  ${layout}
  ${background}
  ${position}
  ${border}
`;

function CustomImage({
  src,
  sizes,
  alt,
  objectFit,
  objectPosition,
  layout,
  fill,
  imgHeight,
  imgWidth,
  ...props
}) {
  const [loaded, setLoaded] = useState(false);
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <ImageWrapper {...props}>
      <Image
        onLoadingComplete={() => {
          if (isMountedRef.current) {
            setLoaded(true);
          }
        }}
        alt={alt}
        src={src}
        height={layout === FILL ? undefined : imgHeight}
        width={layout === FILL ? undefined : imgWidth}
        sizes={sizes}
        layout={layout}
        fill="true"
        objectFit={objectFit}
        objectPosition={objectPosition}
        className={loaded ? "Image" : "Image loading"}
      />
    </ImageWrapper>
  );
}

CustomImage.defaultProps = {
  layout: FILL,
};

CustomImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  style: PropTypes.object,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  imgHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imgWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  layout: PropTypes.string.isRequired,
};

export default CustomImage;
