import Box from "./Box";
import Button from "./Button";
import React from "react";
import { useAppContext } from "./AppContext";

function BurgerStripe(props) {
  return (
    <Box
      bg={props.bg}
      height="2px"
      width="100%"
      left={0}
      top="50%"
      position="absolute"
      mt="-1px"
      transition="transform 200ms, background-color 200ms"
      {...props}
    />
  );
}

function BurgerButton({ bg }) {
  const { setMainMenuOpen } = useAppContext();

  return (
    <Button
      aria-label="Open menu"
      height={11}
      position="relative"
      width={22}
      onClick={() => {
        setMainMenuOpen(true);
      }}
    >
      <BurgerStripe bg={bg} transform="translate3d(0,-4px,0)" />
      <BurgerStripe bg={bg} transform="translate3d(0,4px,0)" />
    </Button>
  );
}

export default BurgerButton;
